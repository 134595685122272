import Vue from 'vue'
import errors from '@/store/errors'
import { METHODS, NOTIFY_STATUS } from '@/const'
const vue = Vue.prototype

const actions = {
	getUpdatesSaved: async function ({ state, commit, getters, rootState }, input) {
		commit('setUpdatesSaved')
		try {
			const result = await rootState.request({
				type: METHODS.GET,
				apiName: 'hqsw',
				url: 'bridge/seowork/monitoring/updates/saved',
				auth: true,
				query: input.query
			})

			if (result &&
				result.has_data &&
				result.data &&
				result.data.items &&
				Array.isArray(result.data.items) &&
				result.data.totals
			) {
				commit('setUpdatesSaved', result.data.items)
				commit('setUpdatesSavedTotals', result.data.totals)
				return true
			}
		} catch (e) {
			vue.$notify({ type: NOTIFY_STATUS.ERROR, title: errors.GET_DATA })
			return false
		}
	},
	getUpdatesShards: async function ({ state, commit, getters, rootState }, input) {
		commit('setUpdatesShards')
		try {
			const result = await rootState.request({
				type: METHODS.GET,
				apiName: 'hqsw',
				url: 'bridge/seowork/monitoring/updates/saved',
				auth: true,
				query: input.query
			})

			if (result &&
				result.has_data &&
				result.data &&
				result.data.available_clickhouse_shards
			) {
				commit('setUpdatesShards', result.data.available_clickhouse_shards)
				return true
			}
		} catch (e) {}
	}
}

export default actions
